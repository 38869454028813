 // * Converts a pixel value to em, relative to the body font size (or any other value).
 // *
 // * Usage:
 // * font-size:em(12px) (if based on body font size)
 // * font-size:em(12px, 18px) (if based on a parent with a relativ font size of 18px)
 // *
 // * @param  {string} $to-size   Target pixel value
 // * @param  {string} $from-size: $base-font-size (optional) Font size of the parent
 // * @return {string}           Calculated em value
@function em($to-size, $from-size: $base-font-size) {
    @if $to-size == 0px { @return 0 }
    @return $to-size / $from-size * 1em;
}

 // * Converts a pixel value to rem, relative to the body font size (or any other value).
 // *
 // * Usage:
 // * font-size:rem(12px) (if based on body font size)
 // * font-size:rem(12px, 18px) (if based on a parent with a relativ font size of 18px)
 // *
 // * @param  {string} $to-size   Target pixel value
 // * @param  {string} $from-size: $base-font-size (optional) Font size of the parent
 // * @return {string}           Calculated rem value
@function rem($to-size, $from-size: $base-font-size) {
    @if $to-size == 0px { @return 0 }
    @return $to-size / $from-size * 1rem;
}

 // * Calculates the percent-based body font size based on a pixel value.
 // *
 // * Usage:
 // *
 // * body {
 // *     font-size:body-font-size();
 // * }
 // *
 // * @type {string} $pixel-value The layout’s base font size
 //
@function body-font-size($pixel-value: $base-font-size) {
    @return ($base-font-size / 1px) * 100 / 16#{'%'};
}

// Cross-browser support for @font-face. Supports IE, Gecko, Webkit, Opera.
//
// * $name is required, arbitrary, and what you will use in font stacks.
// * $font-files is required using font-files('relative/location', 'format').
//   for best results use this order: woff, opentype/truetype, svg
// * $eot is required by IE, and is a relative location of the eot file.
// * $weight shows if the font is bold, defaults to normal
// * $style defaults to normal, might be also italic
// * For android 2.2 Compatiblity, please ensure that your web page has
//   a meta viewport tag.
// * To support iOS < 4.2, an SVG file must be provided
//
// If you need to generate other formats check out the Font Squirrel
// [font generator](http://www.fontsquirrel.com/fontface/generator)
//

// In order to refer to a specific style of the font in your stylesheets as
// e.g. "font-style: italic;",  you may add a couple of @font-face includes
// containing the respective font files for each style and specying
// respective the $style parameter.

// Order of the includes matters, and it is: normal, bold, italic, bold+italic.

@mixin font-face(
  $name,
  $woff,
  $ttf,
  $svg,
  $eot: false,
  $weight: false,
  $style: false
) {
  $iefont: unquote("#{$eot}?#iefix");
  $woff: unquote($woff);
  $ttf: unquote($ttf);
  $svg: unquote(($svg) + '#' + $name);

  @font-face {
    font-family: quote($name);
    $font-files: font-url(quote(fonts/$iefont)) unquote("format('eot')"), font-url(quote(fonts/$woff)) unquote("format('woff')"), font-url(quote(fonts/$ttf)) unquote("format('truetype')"), font-url(quote(fonts/$svg)) unquote("format('svg')");
    src: $font-files;
    @if $weight {
      font-weight: $weight;
    }
    @if $style {
      font-style: $style;
    }
  }
}
