// * Usage: @include pie-clearfix();
@mixin pie-clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    zoom:1;
}

// * Hides text in block or inline-block elements
// * Usage: @include hide-text();
// * $hide-text-direction is defined in _config.scss
@mixin hide-text($direction: $hide-text-direction) {
  @if $direction == left {
    $wider-than-any-screen: -9999em;
    text-indent: $wider-than-any-screen;
    overflow: hidden;
    text-align: left;
  }
  @else {
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
  }
}

// * Appends the relative path to the images folder to the given URL
// * Usage: background-image: image-url("bg-body.jpg")
// * $image-url-path is defined in _config.scss
@function image-url($url) {
  @return url("#{$image-url-path}#{$url}");
}

@mixin absolute {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    width: 100%;
    height: 100%;
}


@function font-url($url) {
  @return url("#{$font-url-path}#{$url}");
}
