/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;    
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.partner-slider {

    li {

        a {
            display: table;
            margin: 0 auto;
        }
    }

    img {
        max-width: rem(200px) !important;
        width: auto !important;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;        
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-dots {
    display:none !important;
}

.slick-arrow {
    display:none !important;

    @include breakpoint(huge3) {
        display:block !important;
        width:rem(48px);
        height:rem(100px);
        border:none;
        text-indent:-9000px;
        overflow:hidden;
        position:absolute;
        top:calc(50% - 50px);
        outline:none;
        cursor:pointer;
        transition:.5s;
        z-index:2;
        background:none;

        &.slick-prev {
            background: {
                image:image-url("layout/slide/prev.png");
                position:center center;
                repeat:no-repeat;
            };
            left:.5%;
        }

        &.slick-next {
            background: {
                image:image-url("layout/slide/next.png");
                position:center center;
                repeat:no-repeat;
            };
            right:.5%;
        }

        &:hover {
            opacity:.5;
        }
    }

    @include breakpoint(huge4) {
        &.slick-prev {
            left:2%;
        }

        &.slick-next {
            right:2%;
        }
    }

    @include breakpoint(huge5) {
        &.slick-prev {
            left:3%;
        }

        &.slick-next {
            right:3%;
        }
    }
}
