// Icons
@include font-face('FontAwesome',
    'fontawesome-webfont.woff',
    'fontawesome-webfont.ttf',
    'fontawesome-webfont.svg',
    'fontawesome-webfont.eot'
);

@include font-face('inconsolataregular',
    'inconsolata-regular-webfont.woff',
    'inconsolata-regular-webfont.ttf',
    'inconsolata-regular-webfont.svg',
    'inconsolata-regular-webfont.eot',
    $weight:400
);

@include font-face('inconsolataregular',
    'inconsolata-bold-webfont.woff',
    'inconsolata-bold-webfont.ttf',
    'inconsolata-bold-webfont.svg',
    'inconsolata-bold-webfont.eot',
    $weight:700
);

@include font-face('galdeanoregular',
    'galdeano-regular-webfont.woff',
    'galdeano-regular-webfont.ttf',
    'galdeano-regular-webfont.svg',
    'galdeano-regular-webfont.eot',
    $weight:400
);

@include font-face('overpasslight',
    'overpass-light-webfont.woff',
    'overpass-light-webfont.ttf',
    'overpass-light-webfont.svg',
    'overpass-light-webfont.eot',
    $weight:300
);

@include font-face('overpasslight',
    'overpass-bold-webfont.woff',
    'overpass-bold-webfont.ttf',
    'overpass-bold-webfont.svg',
    'overpass-bold-webfont.eot',
    $weight:700
);