.navi-main {
    display:flex;
    flex-direction:column;
    justify-content:center;
    font-family:$display2-font;
    font-size:rem(18px);
    width:100%;
    padding:rem(20px) 0 rem(40px);

    @include breakpoint(large) {
        flex-wrap:wrap;
        padding:rem(14px) 0 0;
        flex-direction:row;
        align-items:center;
        position:relative;
        margin-left:6%;
    }

    @include breakpoint(giant) {
        margin-left:3%;
        justify-content:space-between;
        padding:rem(24px) 0 0;
    }

    @include breakpoint(huge) {
        padding:rem(34px) 0 0;
        font-size:rem(20px);
        margin-left:rem(-36px);
    }

    &.mobile {
        display:flex;

        @include breakpoint(large) {
            display:none;
        }
    }

    &.desktop {
        display:none;

        @include breakpoint(large) {
            display:flex;
        }
    }

    li {
        transition:.5s;

        @include breakpoint(large) {
            position:relative;
            padding:0 rem(12px);
            display:flex;
            align-items:center;
            position:relative;
            background:none;
            border-left:none;
        }

        @include breakpoint(giant) {
            padding:0;
        }

        a {
            text-decoration:none;
            color:$light;
            display:block;
            padding:rem(8px) rem(34px);
            transition:.5s;

            @include breakpoint(small) {
                padding:rem(8px) rem($base-gap);
            }

            @include breakpoint(large) {
                padding:0 0 rem(10px);
                color:$secondary;
                border-bottom:1px solid $light;
            }

            &.active {
                position:relative;

                &:after {
                    content:'\f00c';
                    font-family:$icon-font;
                    position:absolute;
                    right:rem($base-gap);
                    top:calc(50% - 2px);
                    transform:translateY(-50%);
                    text-transform:uppercase;
                    color:$light;
                    letter-spacing:rem(1px);
                    font-style:normal;

                    @include breakpoint(large) {
                        display:none;
                    }
                }
            }
        }

        &.hasSub > a {
            font-weight:700;

            @include breakpoint(large) {
                &:after {
                    content:'\f107';
                    font-family:$icon-font;
                    padding-left:rem(6px);
                }
            }
        }

        .sub {

            @include breakpoint(large) {
                width:auto;
                position:absolute;
                top:200%;
                opacity:0;
                left:0;
                visibility:hidden;
                border:none;
                transition:.5s;
                z-index:999;
                white-space:nowrap;
                background:$color3;
                min-width:100%;
                padding:rem(10px) 0;
                display:block;
                text-align:left;
                z-index:10;
            }

            li {
                padding:0;
                padding-left:rem(10px);
                font-style:italic;
                
                @include breakpoint(large) {
                    background:none;
                    z-index:2;
                    position:relative;
                    transition:.5s;
                    padding:0 rem($base-gap);
                }

                &:last-of-type {
                    border-bottom:none;
                }

                a {
                    padding:rem(5px) rem(34px);
                    border:none;

                    @include breakpoint(small) {
                        padding:rem(5px) rem($base-gap);
                    }

                    @include breakpoint(large) {
                        font-size:rem(18px);
                        line-height:rem(22px);
                        padding:rem(10px) rem(5px);
                        position:relative;  
                        font-weight:700;
                        width:100%;
                        color:$light;
                    }

                    &:before {
                        content:'» ';
                    }
                }

                &.active a {
                    
                    @include breakpoint(large) {
                        color:$secondary;
                    }
                }

                &.hovering a, &:hover a {
                    opacity:.8;
                }
            }
        }

        &.hover, &:hover {

            > ul {
                top:100%;
                opacity:1;
                visibility:visible;
            }
        }
        
        &:hover > a {

            @include breakpoint(large) {
                color:$color3;
                border-bottom:1px solid $color3;
            }
        }
        
        &.active > a, > a.active {

            @include breakpoint(large) {
                border-bottom:1px solid $secondary;
            }
        }
    }
}

$menu-bar: 50px;

.page-navi {
    background: {
        image:linear-gradient(to bottom, rgba($secondary,.8) 0,rgba($secondary,.8) 100%);
        position:center center;
        repeat:no-repeat;
        size:cover;
    };
    right:-100%;
    overflow-y:auto;
    position: fixed;
    top:rem($menu-bar);
    transition:.6s;
    width:map-get($breakpoints, tiny) * .8;
    z-index: 895;
    bottom: 0;
    max-width:100%;

    @include breakpoint(large) {
        background:$light;
        bottom:auto;
        right:auto;
        position: relative;
        top:auto;
        width: auto;
        overflow:visible;
    }

    .contact {
        display:none;

        @include breakpoint(large) {
            display:flex;
            justify-content:flex-end;
        }
    }

    .col {
        padding-left:0;
        padding-right:0;

        @include breakpoint(large) {
            padding-left: rem($base-gap);
            padding-right: rem($base-gap);
        }
    }
}

.mobile-bar {
    align-items:center;
    background:$secondary;
    display: flex;
    height:rem($menu-bar);
    justify-content:stretch;
    left:0;
    position:fixed;
    right:0;
    top:0;
    z-index:894;
    font-size:rem(16px);

    @include breakpoint(tiny) {
        font-size:rem(20px);
    }

    li {
        display:flex;
        flex-grow:1;
        padding:0 rem($base-gap);

        &:last-child {
            justify-content:flex-end;
        }
    }

    @include breakpoint(large) {
        display: none;
    }

    .phone, .phone a {
        text-decoration:none;
        color:$light;
    }

    .phone {
        display:flex;
        align-items:center;

        i {
            padding-right:rem(6px);
        }
    }
}

label[for="navi-toggled"] {
    @extend .fa-bars;
    flex-direction:row-reverse;
    color:$light;

    &:before {
        @extend .fa-default;
        margin-right: rem(10px);
    }

    &:after {
        background:rgba($dark,0.8);
        bottom:0;
        content:'';
        left:0;
        opacity: 0;
        pointer-events:none;
        position: fixed;
        top:rem($menu-bar);
        transition:opacity .6s;
        width: 100%;
        z-index:-1;
    }
}

#navi-toggled {
    display: none;

    &:checked {

        ~ * label[for="navi-toggled"] {
            @extend .fa-close;

            &:after {
                opacity: 1;
                pointer-events:auto;
            }
        }

        ~ * .page-navi,
        ~ .page-navi {
            right:0;
        }
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


.navi-add {
    display:none;

    @include breakpoint(large) {
        display:block;
        text-align:right;

        li {
            display:block;
            font-size:rem(20px);
            line-height:rem(30px);
            position:relative;

            a {
                text-decoration:none;
                transition:.5s;
                display:inline-block;
                color:$primary;

                &:hover {
                    opacity:.6;
                }

                &.active {
                    color:$secondary;
                }
            }
        }
    }
}

