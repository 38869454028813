* {
	margin: 0;
	padding: 0;
	outline:none;

	&, &:before, &:after {
		box-sizing: inherit;
	}
}

::selection {}
::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $primary;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);
	font-weight:400;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($base-line-height);
	overflow-x:hidden;

	// Debug mode
	&.the-customer-page #log_hider {
		display: none;
	}

	&.ie-8 #pageloader:after {
		display: none;
	}
}

iframe {
	border:none;
	width: 100%;
}

b, strong {
	font-weight:700;
}

/**
 * General Margin
 */
p, ol, dl, .margin-bottom {
	margin-bottom: rem($base-line-height);
}

/**
 * Links
 */
a {
	color:$secondary;
	text-decoration:underline;
	outline:none;

	&:focus, &:hover, &:active {
		text-decoration:none;
	}

	img {
		border:none;
	}

	&.pdf {
		display:block;
		margin-bottom:rem($base-line-height);
		padding:rem(12px) rem($base-gap) rem(10px) rem(50px);
		color:$primary;
		text-decoration:none;
		transition:.5s;
		background:$color3;
		position:relative;
		
		&:before {
			@extend .fa-default;
			@extend .fa-file-pdf-o;
			color:$secondary;
			position:absolute;
			top:rem(15px);
			left:rem($base-gap);
		}

		&:hover {
			color:$secondary;
		}

		+ .pdf {
			margin-top: rem(-20px);
		}
	}
}

hr {
	border:none;
	clear:both;
	height:1px;
	margin:2rem 0 4rem;
	background:rgba($secondary,.2);

	@include breakpoint(large) {
		margin:7rem 0 7rem;
	}

	&.last:last-child {
		display:none;
	}

	.col & {
		margin:2rem 0 4rem;

		@include breakpoint(large) {
			margin:3rem 0 3rem;
		}
	}
}

ul {
	list-style: none;

	&.styled-list {
		@extend .margin-bottom;

		li {
			padding:0 0 0 rem(20px);
	        position:relative;
	        margin-bottom:rem(10px);

	        &:before {
	        	font-family:$icon-font;
	            content:'\f101';
	            position:absolute;
	            top:0;
	            left:0;
	            display:block;
	        }
		}

		ul {
			margin:rem(20px) 0 0;

			li {
				&:before {
	            	content:'\f105';
	            	color:$primary;
				}
			}
		}
	}

	&.unstyled-list {
		margin-bottom:0;

		li {
			padding-bottom:0;
			padding-top:0;

			&:before {
				content: none;
			}
		}
	}

	&.noSpace {
		margin-bottom:0;
	}
}

ol {
  list-style-type: none;
  counter-reset: item;

  li {
	padding-top: .3rem;
	padding-bottom: .3rem;
	display: table;
	counter-increment: item;

		&:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: 0.3em;    
		}
	}

	ol {
		margin-bottom: 0;

		li {

			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
    float:none;
    height:auto;
    max-width:100%;
    width:100%;

    &[src^='http://cms.'] {
		max-width:none;
		width:auto;
	}

    [data-rel] &, .lightbox-image & {
        margin-bottom:0;
    }
}

/*
 * reset copyright link
 */
[href*='alpen-web.ch'],
[href*='bewertet.de'],
[href*='euroweb.at'],
[href*='euroweb.de'],
[href*='ew.de'],
[href*='geprueft.de'],
[href*='web2walk.com'],
[href*='webstyle.de'],
[href*='webstyle.com'],
[href*='wn-onlineservice.de'],
[href*='internet-media.com'],
[href*='yourrate.com'] {
    display:table;
    text-decoration:none;

    img {
        background:none;
        border-radius:0;
        border:none;
        margin-bottom:5px;
        outline:none;
        padding:0;
        white-space:normal;
        width:auto !important;
    }
}

/**
 * Tables
 */
table {
	@extend .margin-bottom;
	border-collapse: collapse;
	width: 100%;

	caption {
		font-size: 1.2rem;
		font-weight: 700;
		padding-bottom: .5rem;
	}
}

// Responsive table styles
table, thead, tbody, th, td, tr, caption {
	display: block;
}

thead {
	border-collapse: collapse;

	tr {
		position: absolute;
		top: -999%;
		left: -999%;
	}
}

tbody tr {
	border-bottom: 1px solid $medium;
	padding: rem($base-gap) 0;
}

td {
	min-height: 1rem;
	padding: .3rem rem($base-gap) .3rem 35%;
	position: relative;

	&:before {
		content: attr(data-label) ": ";
		font-weight: bold;
		left: 1rem;
		position: absolute;
		top: .3rem;
		white-space: nowrap;
		width: 45%;
	}
}

// Restore proper table display values for larger screens
@include breakpoint(small) {
	table {
		display: table;
	}

	caption {
		display: table-caption;
	}

	thead {
		display: table-header-group;
	}

	tbody {
		display: table-row-group;
	}

	tr {
		display: table-row;
	}

	th, td {
		display: table-cell;
		vertical-align: top;
	}

	td {
		min-height: inherit;
		padding: rem($base-gap) 1rem;

		&:before {
			display: none;
			content: "";
			width: auto;
		}
	}

	thead tr {
		position: static;
		left: auto;
		top: auto;
	}
}
