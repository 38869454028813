
dl.floatList {
    @include pie-clearfix;

    dt, dd {
        display:block;
        vertical-align:top;
        padding:.5rem .8rem;
        background:rgba($secondary,.1);
        border-left:2px solid $secondary;
    }

    dt {
        padding-bottom:0;
        font-style:italic;
        color:$secondary;
        font-weight:700;
    }

    dd {
        margin-bottom:rem(10px);
        padding-top:0;
        position:relative;
    }
}

.highlight {
	color: $secondary;
}

.btn {
	background:$color3;
	border:none;
	color:$light;
	cursor:pointer;
	display:inline-block;
    font-family:$display-font;
	font-size:rem(20px);
	line-height:rem(30px);
	padding:rem(8px) rem($base-gap);
	text-align:center;
	text-decoration:none;
	transition:.5s;
    border-radius:6px;
    margin-bottom:rem($base-gap);

	&:hover {
		background:$dark;
	}

	*[class^="fa"] {
		margin-right:.5rem;
	}

    &.big {
        display:block;
    }

    &.bg {
        background:$color4;

        &:hover {
            background:$dark;
        }
    }

    @include breakpoint(large) {
        padding:rem(16px) rem($base-gap);
        font-size:rem(24px);
        line-height:rem(34px);

        &.spaceLeft {
            margin-left:28%;
        }

        &.spaceRight {
            margin-right:28%;
        }
    }
}

.notification {
	padding:1em;
	background:$alert;
	color:$light;
	font-size:1.2em;
}

.image {
    margin-bottom:rem($base-line-height);
}

.page-wrap {
    padding-top:rem($menu-bar);
    
    @include breakpoint(large) {
        padding-top:0;
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Header
.header {
    position:relative;
    padding:rem(20px) 0 0;
    width:100%;
    margin-bottom:rem(20px);

    @include breakpoint(large) {
        margin-bottom:rem(100px);
        padding-top:rem(136px);
    }

    @include breakpoint(giant) {
        padding-top:rem(133px);
        margin-bottom:rem(150px);
    }

    @include breakpoint(huge) {
        padding-top:rem(180px);
    }

    body.index & {
        @include breakpoint(giant) {
            margin-bottom:rem(170px);
        }

        @include breakpoint(huge) {
             margin-bottom:rem(240px);
        }
    }

    .top {
        margin-bottom:rem(40px);

        @include breakpoint(tiny) {
            margin-bottom:rem(10px);
        }

        @include breakpoint(large) {
            margin-bottom:0;
            position:fixed;
            top:0;
            left:0;
            right:0;
            z-index:222;
            background:$light;
            height:rem(136px);
            box-shadow: 0 0 30px 0 rgba(#000,.2);
            transition:.5s;
        }

        @include breakpoint(giant) {
            height:rem(133px);
        }

        @include breakpoint(huge) {
            height:rem(180px);
        }

        .branding {
            text-align:center;
            display:block;
            margin-bottom:rem(20px);

            @include breakpoint(large) {
                text-align:left;
                display:inline-block;
                margin:rem(25px) 0 rem(17px);
                transition:.5s;
            }

            @include breakpoint(giant) {
                margin:rem(35px) 0 rem(27px);
            }

            img {
                width:auto;

                @include breakpoint(large) {
                    transition:.5s;
                    max-width:217px;
                }

                @include breakpoint(giant) {
                    max-width:100%;
                }
            }
        }

        .contact {
            text-align:center;
            font-family:$display-font;

            @include breakpoint(large) {
                padding-left:11%;
            }

            li {

                &.phone {
                    background:$color3;
                    color:$light;
                    font-size:rem(22px);
                    line-height:rem(32px);
                    border-radius:6px;
                    transition:.5s;
                    padding:rem(8px) rem($base-gap);
                    margin-bottom:rem(12px);

                    @include breakpoint(large) {
                        padding:rem(12px) rem($base-gap);
                        margin-bottom:rem(8px);
                    }

                    @include breakpoint(giant) {
                        margin-bottom:rem(12px);
                    }

                    @include breakpoint(huge) {
                        padding:rem(18px) rem($base-gap);
                        font-size:rem(32px);
                        line-height:rem(42px);
                    }

                    a {
                        color:$light;
                        text-decoration:none;
                    }
                }

                &.mail {
                    font-size:rem(20px);
                    line-height:rem(30px);
                    color:$secondary;

                    a {
                        color:$secondary;
                        text-decoration:none;
                        transition:.5s;

                        &:hover {
                            color:$primary;
                        } 

                        &:before {
                            font-family:$icon-font;
                            content:'\f040';
                            padding-right:rem(6px);
                            font-size:rem(17px);
                        }
                    }
                }
            }
        }

        &.scroll {
            
            @include breakpoint(large) {
                height:rem(120px);

                .branding {
                    margin:rem(38px) 0 rem(17px);

                    img {
                        max-width:60%;
                    }
                }

                .contact li.phone {
                    padding:rem(8px) rem($base-gap);
                }
            }

            @include breakpoint(giant) {
                .branding {
                    margin:rem(32px) 0 rem(24px);

                    img {
                        max-width:90%;
                    }
                }
            }

            @include breakpoint(huge) {
                .branding {
                    margin:rem(25px) 0 rem(17px);

                    img {
                        max-width:60%;
                    }
                }

                .contact li.phone {
                    font-size:rem(28px);
                    line-height:rem(38px);
                }
            }
        }
    }

    .claim {
        background: {
            image:linear-gradient(to bottom, rgba($secondary,.7) 0,rgba($secondary,.7) 100%), image-url("layout/helmut-thaler-sachverstaendiger-1.jpg");
            position:center center;
            repeat:no-repeat;
            size:cover;
        };
        text-align:center;
        color:$light;
        padding:rem(40px) 0;
        display:none;

        body.index & {
            display:block;
        }

        @include breakpoint(large) {
            display:none;
        }

        @include breakpoint(giant) {
            padding:rem(60px) 0;
            background-attachment:fixed;
            display:none;
            align-items:center;

            body.index & {
                display:flex;
                height:calc(100vh - 354px);
                min-height:rem(640px);
                padding:0;
            }

            body.iexplore & {
                padding-top:rem(120px);
            }
        }

        p {

            &:first-child {
                font-family:$display-font;
                font-size:rem(22px);
                line-height:rem(27px);
                margin-bottom:rem(12px);

                @include breakpoint(large) {
                    font-size:rem(40px);
                    line-height:rem(45px);
                }

                @include breakpoint(giant) {
                    font-size:rem(50px);
                    line-height:rem(55px);
                }

                strong {
                    display:block;
                }
            }

            &:nth-child(2) {
                text-transform:uppercase;
                font-family:$display2-font;
                font-size:rem(18px);
                line-height:rem(28px);
                margin-bottom:0;

                @include breakpoint(large) {

                    &:before, &:after {
                        content:' - ';
                    }
                }

                @include breakpoint(giant) {
                    font-size:rem(20px);
                    line-height:rem(30px);
                }

                body.index & {
                    margin-bottom:rem(20px);

                    @include breakpoint(large) {
                        margin-bottom:rem(38px);
                    }
                }
            }
        }
    }

    .teaser {
        margin-bottom:rem(60px);

        @include breakpoint(giant) {
            margin-top:-125px;
        }

        a {
            text-decoration:none;
            color:$color4;
            background:$light;
            font-family:$display-font;
            font-size:rem(18px);
            line-height:rem(24px);
            display:block;
            margin-top:rem(60px);
            text-align:center;
            transition:.5s;

            @include breakpoint(large) {
                font-size:rem(20px);
                line-height:rem(26px);
            }

            @include breakpoint(giant) {
                margin-top:0;
                text-align:left;
                font-size:rem(18px);
                line-height:rem(24px);
            }

            @include breakpoint(huge) {
                font-size:rem(22px);
                line-height:rem(28px);
            }

            .text {
                display:block;
            }

            .image {
                max-width:200px;
                margin-bottom:rem(10px);
                transition:.5s;

                @include breakpoint(large) {
                    max-width:100%;
                    margin-bottom:rem(20px);
                }

                @include breakpoint(giant) {
                    margin-bottom:rem(10px);
                }

                @include breakpoint(huge) {
                    margin-bottom:rem(20px);
                }
            }

            &:hover {
                color:$secondary;

                .image {
                    opacity:.6;
                }
            }
        }
    }

    .sliderBox {
        background:$secondary;

        body.index & {
            display: none;
        }

        .slider, .slider .slide {
            height:360px;

            @include breakpoint(large) {
                height:444px;
            }
        }

        .slider {
            position:relative;
            z-index:1;
            overflow:hidden;

            .slide {
                width:100%;
                position:relative;
                text-align:center;
                color:$light;
                background:$secondary;
                display:flex;
                align-items:center;
                padding:rem(40px) 0;

                @include breakpoint(large) {
                    text-align:left;
                    padding:0;
                }

                @for $i from 1 through 7 {
                    &.slide-#{$i} {
                        background: {
                            image:linear-gradient(to right, rgba($secondary,.6) 0, rgba($secondary,.6) 100%), image-url("layout/slide/helmut-thaler-sachverstaendiger-#{$i}.jpg");
                            position:center center;
                            repeat:no-repeat;
                            size:cover;
                        };

                        @include breakpoint(large) {
                            background-image:linear-gradient(to right, rgba($secondary,1) 0, rgba($secondary,0) 80%), image-url("layout/slide/helmut-thaler-sachverstaendiger-#{$i}.jpg");
                        }
                    }
                }

                .title {
                    display:block;
                    font-family:$display-font;
                    font-size:rem(24px);
                    line-height:rem(34px);
                    text-transform:uppercase;
                    margin-bottom:rem(8px);

                    @include breakpoint(large) {
                        font-size:rem(40px);
                        line-height:rem(50px);
                    }

                    @include breakpoint(giant) {
                        font-size:rem(48px);
                        line-height:rem(58px);
                    }
                }

                p {
                    font-size:rem(18px);
                    line-height:rem(24px);
                    margin-bottom:rem(20px);

                    @include breakpoint(large) {
                        font-size:rem(22px);
                        line-height:rem(32px);
                        margin-bottom:rem(40px);
                    }

                    br {
                        display:none;

                        @include breakpoint(large) {
                            display:inline;
                        }
                    }
                }

                .btn {
                    background:none;
                    font-weight:400;
                    border:1px solid $light;
                    font-size:rem(22px);
                    margin-bottom:0;

                    @include breakpoint(large) {
                        padding:rem(16px) rem(60px);
                    }
                }
            }
        }        
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Content
.content {

    h1, h2, h3, .h1, .h2, .h3 {
        text-rendering:optimizeLegibility;
    }

    h1, .h1 {
        font-family:$display-font;
        font-size:rem(24px);
        line-height:rem(34px);
        font-weight:400;
        color:$secondary;
        text-transform:uppercase;
        margin-bottom:rem(40px);

        @include breakpoint(large) {
            font-size:rem(36px);
            line-height:rem(46px);
            margin-bottom:rem(60px);
        }

        @include breakpoint(giant) {
            font-size:rem(40px);
            line-height:rem(50px);
            margin-bottom:rem(90px);
        }

        @include breakpoint(huge) {
            font-size:rem(48px);
            line-height:rem(58px);
            padding-left:rem(30px);
            margin-bottom:rem(120px);
            position:relative;

            &:before {
                content:'';
                width:100%;
                height:130px;
                display:block;
                background:$color5;
                position:absolute;
                top:calc(50% - 65px);
                left:calc(-100% - 18px);
            }
        }
    }

    h2, .h2 {
        font-family:$display-font;
        font-size:rem(20px);
        line-height:rem(30px);
        font-weight:700;
        color:$secondary;
        text-transform:uppercase;
        margin-bottom:rem(10px);

        @include breakpoint(large) {
            font-size:rem(24px);
            line-height:rem(34px);
        }
    }

    h3, .h3 {
        font-size:rem(18px);
        line-height:rem(28px);
        font-weight:400;
        color:$secondary;
        margin-bottom:rem(10px);

        @include breakpoint(large) {
            font-size:rem(24px);
            line-height:rem(34px);
        }
    }

	ul {
		@extend .styled-list;
	}

	.google-maps {
		margin-bottom:rem($base-line-height);

		iframe {
	        border:none;
	        width:100%;
			height:rem(400px);

			@include breakpoint(large) {
				height:rem(600px);
			}
		}

        body.nojs & {
            display:none;
        }
	}

    .relative {
        position:relative;
    }

    .imageBox {

        @include breakpoint(large) {
            position:absolute;
            bottom:0;
            left:rem($base-gap);
            top:0;
            width:calc(100% - 18px);
            background-position:center center;
            background-size:cover;
            margin-bottom:rem(31px);
        }

        @include breakpoint(huge2) {
            width:calc(100vw - (#{rem($row-max-width)} - 100%) - ((100vw - #{rem($row-max-width)}) / 2)); 
        }
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Footer
.footer {
    padding-top:rem(60px);

    @include breakpoint(large) {
        padding-top:rem(140px);

        body.index & {
            padding-top: rem(200px);
        }
    }

    .sliderBox {
        background:$secondary;
        display: none;

        body.index & {
            display: block;
        }

        .slider, .slider .slide {
            height:360px;

            @include breakpoint(large) {
                height:444px;
            }
        }

        .slider {
            position:relative;
            z-index:1;
            overflow:hidden;

            .slide {
                width:100%;
                position:relative;
                text-align:center;
                color:$light;
                background:$secondary;
                display:flex;
                align-items:center;
                padding:rem(40px) 0;

                @include breakpoint(large) {
                    text-align:left;
                    padding:0;
                }

                @for $i from 1 through 7 {
                    &.slide-#{$i} {
                        background: {
                            image:linear-gradient(to right, rgba($secondary,.6) 0, rgba($secondary,.6) 100%), image-url("layout/slide/helmut-thaler-sachverstaendiger-#{$i}.jpg");
                            position:center center;
                            repeat:no-repeat;
                            size:cover;
                        };

                        @include breakpoint(large) {
                            background-image:linear-gradient(to right, rgba($secondary,1) 0, rgba($secondary,0) 80%), image-url("layout/slide/helmut-thaler-sachverstaendiger-#{$i}.jpg");
                        }
                    }
                }

                .title {
                    display:block;
                    font-family:$display-font;
                    font-size:rem(24px);
                    line-height:rem(34px);
                    text-transform:uppercase;
                    margin-bottom:rem(8px);

                    @include breakpoint(large) {
                        font-size:rem(40px);
                        line-height:rem(50px);
                    }

                    @include breakpoint(giant) {
                        font-size:rem(48px);
                        line-height:rem(58px);
                    }
                }

                p {
                    font-size:rem(18px);
                    line-height:rem(24px);
                    margin-bottom:rem(20px);

                    @include breakpoint(large) {
                        font-size:rem(22px);
                        line-height:rem(32px);
                        margin-bottom:rem(40px);
                    }

                    br {
                        display:none;

                        @include breakpoint(large) {
                            display:inline;
                        }
                    }
                }

                .btn {
                    background:none;
                    font-weight:400;
                    border:1px solid $light;
                    font-size:rem(22px);
                    margin-bottom:0;

                    @include breakpoint(large) {
                        padding:rem(16px) rem(60px);
                    }
                }
            }
        }        
    }

    .last {
        padding:rem(60px) 0 rem(80px);
        font-size:rem(18px);
        line-height:rem(28px);
        text-align:center;

        @include breakpoint(large) {
            text-align:left;
            padding:rem(80px) 0 rem(120px);
        }

        @include breakpoint(giant) {
            font-size:rem(20px);
            line-height:rem(30px);
        }

        hr {
            display:none;

            @include breakpoint(giant) {
                display:block;
                background:$secondary;
                margin:0;
            }
        }

        a {
            color:$primary;
        }
    }
}

.StickyBottomRight {
    bottom: rem(70px) !important;
}
.WidgetPopup {
    top: 50px !important;
    @include breakpoint(large) {
        top: unset !important;
    }
}